/* eslint-disable max-len */
import { Component } from '@angular/core';
import { ContextService } from 'src/app/services/context.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-vorversicherungen-hausrat',
  templateUrl: './vorversicherungen-hausrat.component.html',
  styleUrls: ['./vorversicherungen-hausrat.component.scss'],
})
export class VorversicherungenHausratComponent {

  versichererValid = '';
  versicherungsnummerValid = '';
  anzahlVorschaedenValid = '';
  beschreibungVorschaedenValid = '';

  constructor(public cs: ContextService, public vs: ValidationService) { }

  validateFormField(fieldName: string) {
    if (fieldName === 'versicherer') {
      this.versichererValid = this.vs.validateField(this.cs.process.antrag.vorversicherungen.hausrat.versicherer);
    }
    if (fieldName === 'versicherungsnummer') {
      this.versicherungsnummerValid = this.vs.validateField(this.cs.process.antrag.vorversicherungen.hausrat.versicherungsnummer);
    }
    if (fieldName === 'anzahlVorschaeden') {
      this.anzahlVorschaedenValid = this.vs.validateField(this.cs.process.antrag.vorversicherungen.hausrat.anzahl_vorschaeden);
    }
    if (fieldName === 'beschreibungVorschaeden') {
      this.beschreibungVorschaedenValid = this.vs.validateField(this.cs.process.antrag.vorversicherungen.hausrat.beschreibung_vorschaeden);
    }
  }

}
