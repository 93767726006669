/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HaftpflichtDataService {

  haftpflichtData = {
    version: '202201',
    taxRate: 0.19,
    product: {
      name: 'Haftpflichtversicherung',
      short: 'Haftpflicht'
    },
    tarife: {
      premium: {
        name: 'Premium',
        gruppen: {
          familie: {
            name: 'Familien',
            jnp: 4523
          },
          single: {
            name: 'Single',
            jnp: 3086
          },
          senior: {
            name: 'Senioren',
            jnp: 2727
          }
        }
      },

      premium_plus: {
        name: 'Premium-Plus',
        gruppen: {
          familie: {
            name: 'Familien',
            jnp: 7083
          },
          single: {
            name: 'Single',
            jnp: 5059
          },
          senior: {
            name: 'Senioren',
            jnp: 4047
          }
        }
      }
    }
  };

  constructor() { }
}
