/* eslint-disable max-len */
import { Component, EventEmitter, Output } from '@angular/core';
import { ContextService } from 'src/app/services/context.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-zahlweise',
  templateUrl: './zahlweise.component.html',
  styleUrls: ['./zahlweise.component.scss'],
})
export class ZahlweiseComponent {
  @Output() recalculate = new EventEmitter<boolean>();

  constructor(public cs: ContextService, public notificationService: NotificationService){  }

  onZahlweiseChanged() {
    this.notificationService.publishSomeData({
      recalculate: true,
      tariflinie: false,
      payment_frequency: true
  });
  }

}
