/* eslint-disable @typescript-eslint/dot-notation */
import { Component, OnInit } from '@angular/core';
import { LoadingController, NavController } from '@ionic/angular';
import { ContextService } from './services/context.service';
import { UserService } from './services/user.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  appPages = [
    { title: 'Home', url: '/home', icon: 'home', dynamicVisibility: false },
    { title: 'Tarifierung', url: '/tarifierung', icon: 'calculator', dynamicVisibility: true },
    { title: 'Suche', url: '/angebot-suchen', icon: 'search', dynamicVisibility: true },
    { title: 'Kontakt', url: '/kontakt', icon: 'call', dynamicVisibility: false },
    /*{ title: 'Einstellungen', url: '/einstellungen', icon: 'construct', dynamicVisibility: false }*/
  ];

  constructor(private navCtrl: NavController, public userService: UserService,
    private loadingCtrl: LoadingController, public cs: ContextService) {
  }

  async ngOnInit() {
    const token = localStorage.getItem('authToken');
    if (token) {
      const loading = await this.loadingCtrl.create({
        spinner: 'circles',
        message: ''
      });
      await loading.present();
      try {
        const res = await this.userService.refreshToken();
        localStorage.setItem('authToken', res['access_token']);
        localStorage.setItem('userId', res['user']['id']);
        this.userService.isLoggedIn = true;
        this.cs.user.email = res['user']['email'];
        const signatureRes = await this.userService.getUserStatus();
        if (signatureRes['data']['status'].length === 0) {
          this.userService.userSigned = true;
          localStorage.setItem('userSigned', String(this.userService.userSigned));
        } else {
          localStorage.removeItem('userSigned');
        }
        await loading.dismiss();
        this.navCtrl.navigateRoot('/home');
      } catch (error) {
        await loading.dismiss();
      }
    }
  }

  navigateTo(url: string) {
    this.navCtrl.navigateRoot(url);
  }
}
