/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HausratDataService {

  glasData = {
    version: '202201',

    product: {
      name: 'Glasversicherung',
      short: 'Glas'
    },

    taxRate: 0.19,

    tarife: {
      premium: {
        name: 'Premium',
        perqm: 23.67
      },
      premium_plus: {
        name: 'Premium Plus',
        perqm: 34.36
      }
    }
  };

  hausratData = {
    version: '202201',
    taxRate: 0.1615,
    taxRateElementar: 0.1615,
    product: {
      name: 'Hausratversicherung',
      short: 'Hausrat'
    },
    tarife: {
      premium: {
        name: 'Premium',
        smart_home: 2047,
        tarifzonen: {
          1: 45.4661016949152,
          2: 49.271186440678,
          3: 58.0169491525424,
          4: 79.0012711864407,
          5: 93.6444915254237,
          6: 106.782627118644,
        },
        tarifzonenFahrraddiebstahl: {
          1: {
            1000: 28.3050847457627,
            2000: 112.203389830508,
            3000: 192.203389830508,
            4000: 270.847457627119,
            5000: 347.796610169491,
            6000: 421.525423728813,
            7000: 495.593220338983,
            8000: 567.966101694915,
            9000: 638.813559322034,
            10000: 708.305084745763,
          },
          2: {
            1000: 47.9661016949152,
            2000: 190.677966101695,
            3000: 326.779661016949,
            4000: 460.338983050847,
            5000: 591.35593220339,
            6000: 716.610169491525,
            7000: 842.372881355932,
            8000: 965.593220338983,
            9000: 1086.10169491525,
            10000: 1204.06779661017,
          },
          3: {
            1000: 56.4406779661017,
            2000: 224.237288135593,
            3000: 384.406779661017,
            4000: 541.694915254237,
            5000: 695.762711864407,
            6000: 843.050847457627,
            7000: 991.016949152542,
            8000: 1135.93220338983,
            9000: 1277.79661016949,
            10000: 1416.61016949153,
          },
          4: {
            1000: 56.4406779661017,
            2000: 224.237288135593,
            3000: 384.406779661017,
            4000: 541.694915254237,
            5000: 695.762711864407,
            6000: 843.050847457627,
            7000: 991.016949152542,
            8000: 1135.93220338983,
            9000: 1277.79661016949,
            10000: 1416.61016949153,
          },
          5: {
            1000: 60.6779661016949,
            2000: 241.016949152542,
            3000: 413.389830508475,
            4000: 582.203389830508,
            5000: 747.966101694915,
            6000: 906.440677966101,
            7000: 1065.42372881356,
            8000: 1221.18644067797,
            9000: 1373.5593220339,
            10000: 1522.8813559322,
          },
          6: {
            1000: 60.6779661016949,
            2000: 241.016949152542,
            3000: 413.389830508475,
            4000: 582.203389830508,
            5000: 747.966101694915,
            6000: 906.440677966101,
            7000: 1065.42372881356,
            8000: 1221.18644067797,
            9000: 1373.5593220339,
            10000: 1522.8813559322,
          }
        },
        zuersZonen: {
          1: 18.15,
          2: 51.41,
          3: 233.59
        }
      },
      premium_plus: {
        name: 'Premium Plus',
        smart_home: 2047,
        tarifzonen: {
          1: 49.1525423728813,
          2: 50.8474576271186,
          3: 64.2881355932203,
          4: 85.4067796610169,
          5: 101.237288135593,
          6: 115.440677966102,
        },
        tarifzonenFahrraddiebstahl: {
          1: {
            2000: 83.8714780677107,
            3000: 163.977492808691,
            4000: 242.551192708717,
            5000: 319.592577767789,
            6000: 393.298018564644,
            7000: 467.274773941808,
            8000: 539.719214478019,
            9000: 610.631340173275,
            10000: 680.011151027578,
          },
          2: {
            2000: 142.584368400436,
            3000: 278.767320937621,
            4000: 412.345286072395,
            5000: 543.318263804757,
            6000: 668.620022707956,
            7000: 794.383025635495,
            8000: 917.541041160623,
            9000: 1038.09406928334,
            10000: 1156.04211000364,
          },
          3: {
            2000: 167.742956135421,
            3000: 327.954985617382,
            4000: 485.102385417434,
            5000: 639.185155535578,
            6000: 786.596037129288,
            7000: 934.549547883617,
            8000: 1079.43842895604,
            9000: 1221.26268034655,
            10000: 1360.02230205516,
          },
          4: {
            2000: 167.742956135421,
            3000: 327.954985617382,
            4000: 485.102385417434,
            5000: 639.185155535578,
            6000: 786.596037129288,
            7000: 934.549547883617,
            8000: 1079.43842895604,
            9000: 1221.26268034655,
            10000: 1360.02230205516,
          },
          5: {
            2000: 180.32700947846,
            3000: 352.558123228673,
            4000: 521.49469920258,
            5000: 687.136737400181,
            6000: 845.606362920056,
            7000: 1004.65932556505,
            8000: 1160.41775043373,
            9000: 1312.88163752611,
            10000: 1462.05098684218,
          },
          6: {
            2000: 180.32700947846,
            3000: 352.558123228673,
            4000: 521.49469920258,
            5000: 687.136737400181,
            6000: 845.606362920056,
            7000: 1004.65932556505,
            8000: 1160.41775043373,
            9000: 1312.88163752611,
            10000: 1462.05098684218,
          }
        },
        zuersZonen: {
          1: 18.15,
          2: 51.41,
          3: 233.59
        }
      }
    },
  };

  constructor() { }
}
