import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-mitarbeiter-modal',
  templateUrl: './mitarbeiter-modal.component.html',
  styleUrls: ['./mitarbeiter-modal.component.scss'],
})
export class MitarbeiterModalComponent {

  @Input() streets: any;

  constructor(public modalCtrl: ModalController) { }

  selectMitarbeiter(mitarbeiter: any) {
    this.modalCtrl.dismiss({
      selectedMitarbeiter: mitarbeiter
    });
  }

}
