
import { Component, EventEmitter, Input } from '@angular/core';
import { WohngebaeudeService } from 'src/app/services/calculators/wohngebaeude.service';

import { addDays, addYears, getDate, getYear, getDaysInMonth, parseISO } from 'date-fns';
import { ContextService } from 'src/app/services/context.service';
import { ValidationService } from 'src/app/services/validation.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-wohngebaeude',
  templateUrl: './wohngebaeude.component.html',
})
export class WohngebaeudeComponent {

  alterInvalid: string;
  qmInvalid: string;
  buildingAge;
  calculationDone: boolean = false;

  constructor(public vs: ValidationService, public cs: ContextService, public notificationService: NotificationService, public calulator: WohngebaeudeService) {
  }

  recalculate() {
    this.calulator.getCalculation();
    this.calculationDone = Number.isFinite(this.cs.process.products.wohngebaeude.calculation.gesamtZahlGemZW);
  }


  ngOnInit() {
    this.notificationService.getObservable().subscribe((data) => {
      console.log('Data received', data);
      if (data.recalculate) {
        try {
          this.recalculate();
        } catch(err) {
          console.log("catched", err);
        }
      }
  });
  }

  calcBuildingAge() {
    this.buildingAge = getYear(parseISO(this.cs.process.products.wohngebaeude.input.begin)) - this.cs.process.products.wohngebaeude.input.ursprungsbaujahr;
  }

  validateAlter() {
    this.calcBuildingAge();
    this.alterInvalid = this.vs.validateAlter(this.buildingAge);
  }

  validateQm() {
    this.qmInvalid = this.vs.validateQm(this.cs.process.products.wohngebaeude.input.wohnflaeche);
  }


}
