/* eslint-disable max-len */
import { Component } from '@angular/core';
import { ContextService } from 'src/app/services/context.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-vorversicherungen-haftpflicht',
  templateUrl: './vorversicherungen-haftpflicht.component.html',
  styleUrls: ['./vorversicherungen-haftpflicht.component.scss'],
})
export class VorversicherungenHaftpflichtComponent {

  versichererValid = '';
  versicherungsnummerValid = '';
  anzahlVorschaedenValid = '';
  beschreibungVorschaedenValid = '';

  constructor(public cs: ContextService, public vs: ValidationService) { }

  validateFormField(fieldName: string) {
    if (fieldName === 'versicherer') {
      this.versichererValid = this.vs.validateField(this.cs.process.antrag.vorversicherungen.haftpflicht.versicherer);
    }
    if (fieldName === 'versicherungsnummer') {
      this.versicherungsnummerValid = this.vs.validateField(this.cs.process.antrag.vorversicherungen.haftpflicht.versicherungsnummer);
    }
    if (fieldName === 'anzahlVorschaeden') {
      this.anzahlVorschaedenValid = this.vs.validateField(this.cs.process.antrag.vorversicherungen.haftpflicht.anzahl_vorschaeden);
    }
    if (fieldName === 'beschreibungVorschaeden') {
      this.beschreibungVorschaedenValid = this.vs.validateField(this.cs.process.antrag.vorversicherungen.haftpflicht.beschreibung_vorschaeden);
    }
  }

}
