/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WohngebaeudeDataService {

  glasData = {
    version: '202201',

    product: {
      name: 'Glasversicherung',
      short: 'Glas'
    },

    taxRate: 0.19,

    tarife: {
      premium: {
        name: 'Premium',
        perqm: 23.67
      },
      premium_plus: {
        name: 'Premium Plus',
        perqm: 34.36
      }
    }
  };

  wohngebaeudeData = {
    version: '202201',
    taxRate: 0.1634,
    taxRateElementar: 0.1634,
    product: {
      name: 'Wohngebäudeversicherung',
      short: 'Wohngebäude'
    },
    alter: {
      neu: 'Neubau',
      ab8: 'ab 8 Jahren',
      ab17: 'ab 17 Jahren'
    },
    tarife: {
      premium: {
        name: 'Premium',
        smart_home: 6030.91,
        zonen: {
          neu: {
            '1/1': 91.0445454545454,
            '2/1': 106.508181818182,
            '3/1': 128.844545454545,
            '4/1': 147.744545454545,
            '1/2': 109.944545454545,
            '2/2': 125.408181818182,
            '3/2': 142.59,
            '4/2': 166.644545454545,
          },
          ab8: {
            '1/1': 113.818181818182,
            '2/1': 133.145454545455,
            '3/1': 161.054545454545,
            '4/1': 184.690909090909,
            '1/2': 137.436363636364,
            '2/2': 156.781818181818,
            '3/2': 178.236363636364,
            '4/2': 208.309090909091,
          },
          ab17: {
            '1/1': 139.420909090909,
            '2/1': 163.093636363636,
            '3/1': 197.304545454545,
            '4/1': 226.227272727273,
            '1/2': 168.362727272727,
            '2/2': 192.035454545455,
            '3/2': 218.342727272727,
            '4/2': 255.169090909091,
          }
        },
        zuersZonen: {
          1: 51.33,
          2: 206.36,
          3: 907.64
        }
      },
      premium_plus: {
        name: 'Premium Plus',
        smart_home: 6030.91,
        zonen_ohne_ug_mg: {
          neu: {
            '1/1': 114.545454545455,
            '2/1': 133.636363636364,
            '3/1': 135.545454545455,
            '4/1': 154.636363636364,
            '1/2': 160.363636363636,
            '2/2': 179.454545454545,
            '3/2': 185.181818181818,
            '4/2': 202.363636363636,
          },
          ab8: {
            '1/1': 143.181818181818,
            '2/1': 166.090909090909,
            '3/1': 171.818181818182,
            '4/1': 194.727272727273,
            '1/2': 200.454545454545,
            '2/2': 225.272727272727,
            '3/2': 231,
            '4/2': 253.909090909091,
          },
          ab17: {
            '1/1': 175.636363636364,
            '2/1': 204.272727272727,
            '3/1': 210,
            '4/1': 238.636363636364,
            '1/2': 246.272727272727,
            '2/2': 276.818181818182,
            '3/2': 282.545454545455,
            '4/2': 311.181818181818,
          }
        },
        zonen: {
          neu: {
            '1/1': 125.454545454545,
            '2/1': 146.363636363636,
            '3/1': 148.454545454545,
            '4/1': 169.363636363636,
            '1/2': 175.636363636364,
            '2/2': 196.545454545455,
            '3/2': 202.818181818182,
            '4/2': 221.636363636364,
          },
          ab8: {
            '1/1': 156.818181818182,
            '2/1': 181.909090909091,
            '3/1': 188.181818181818,
            '4/1': 213.272727272727,
            '1/2': 219.545454545455,
            '2/2': 246.727272727273,
            '3/2': 253,
            '4/2': 278.090909090909,
          },
          ab17: {
            '1/1': 192.363636363636,
            '2/1': 223.727272727273,
            '3/1': 230,
            '4/1': 261.363636363636,
            '1/2': 269.727272727273,
            '2/2': 303.181818181818,
            '3/2': 309.454545454545,
            '4/2': 340.818181818182,
          }
        },
        zuersZonen: {
          1: 51.33,
          2: 206.36,
          3: 907.64
        }
      }
    },
  };

  constructor() { }
}
