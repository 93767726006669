import { Component } from '@angular/core';
import { LoadingController, NavController } from '@ionic/angular';
import { ContextService } from 'src/app/services/context.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent {

  constructor(public cs: ContextService, private userService: UserService,
    private navCtrl: NavController, private loadingCtrl: LoadingController) { console.log("login view");}

  async onLogin() {
    const loading = await this.loadingCtrl.create({
      spinner: 'circles',
      message: ''
    });
    await loading.present();
    try {
      const res = await this.userService.login(this.cs.user.email, this.cs.user.password);
      localStorage.setItem('authToken', res['access_token']);
      localStorage.setItem('userId', res['user']['id']);
      this.userService.isLoggedIn = true;
      const signatureRes = await this.userService.getUserStatus();
      if (signatureRes['data']['status'].length === 0) {
        this.userService.userSigned = true;
        localStorage.setItem('userSigned', String(this.userService.userSigned));
      } else {
        localStorage.removeItem('userSigned');
      }
      await loading.dismiss();
      this.navCtrl.navigateRoot('/home');
    } catch (error) {
      await loading.dismiss();
    }
  }

  async onPasswordAnfordern() {
    const loading = await this.loadingCtrl.create({
      spinner: 'circles',
      message: ''
    });
    await loading.present();
    try {
      const res = await this.userService.forgotPassword();
      this.navCtrl.navigateForward('/reset-password');
      await loading.dismiss();
    } catch (error) {
      await loading.dismiss();
    }
  }

}
