
import { Component, EventEmitter, Input } from '@angular/core';
import { HaftpflichtService } from 'src/app/services/calculators/haftpflicht.service';

import { ContextService } from 'src/app/services/context.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-haftpflicht',
  templateUrl: './haftpflicht.component.html',
})
export class HaftpflichtComponent {
  calculationDone: boolean = false;

  constructor(public cs: ContextService, public notificationService: NotificationService, public haftCalc: HaftpflichtService) {
  }

  recalculate() {
    this.haftCalc.getCalculation();
    this.calculationDone = Number.isFinite(this.cs.process.products.haftpflicht.calculation.gesamtZahlGemZW);
  }

  ngOnInit() {
    this.notificationService.getObservable().subscribe((data) => {
      console.log('Data received', data);
      if (data.recalculate) {
        try {
          this.recalculate();
        } catch(err) {
          console.log("catched", err);
        }
      }
  });
  }


}
