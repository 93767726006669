import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { HaftpflichtComponent } from './haftpflicht/haftpflicht.component';
import { FooterComponent } from './footer/footer.component';
import { AddressFieldsComponent } from './address-fields/address-fields.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { StandardButtonComponent } from './standard-button/standard-button.component';
import { TarifTabsComponent } from './tarif-tabs/tarif-tabs.component';
import { ClientFieldsComponent } from './client-fields/client-fields.component';
import { SepaFieldsComponent } from './beitragszahlun-fields/beitragszahlung-fields.component';
import { VorversicherungenHaftpflichtComponent } from './vorversicherungen-haftpflicht/vorversicherungen-haftpflicht.component';
import { VorversicherungenHausratComponent } from './vorversicherungen-hausrat/vorversicherungen-hausrat.component';
import { VorversicherungenWohngebaudeComponent } from './vorversicherungen-wohngebaude/vorversicherungen-wohngebaude.component';
import { VorversicherungenUnfallComponent } from './vorversicherungen-unfall/vorversicherungen-unfall.component';
import { ZahlweiseComponent } from './zahlweise/zahlweise.component';
import { ModulesListComponent } from './modules-list/modules-list.component';
import { TarifsCalculationComponent } from './tarifs-calculation/tarifs-calculation.component';
import { ModuleItemComponent } from './module-item/module-item.component';
import { PipesModule } from '../pipes/pipes.module';
import { AddressModalComponent } from './address-modal/address-modal.component';
import { MitarbeiterModalComponent } from './mitarbeiter-modal/mitarbeiter-modal.component';
import { BrMaskerModule } from 'br-mask';
import { TariflinieComponent } from './tariflinie/tariflinie.component';
import { WohngebaeudeComponent } from './wohngebaeude/wohngebaeude.component';
import { HausratComponent } from './hausrat/hausrat.component';

@NgModule({
    declarations: [
        HeaderComponent,
        LoginComponent,
        HaftpflichtComponent,
        HausratComponent,
        WohngebaeudeComponent,
        TariflinieComponent,
        FooterComponent,
        AddressFieldsComponent,
        DatePickerComponent,
        StandardButtonComponent,
        TarifTabsComponent,
        ClientFieldsComponent,
        SepaFieldsComponent,
        VorversicherungenHaftpflichtComponent,
        VorversicherungenHausratComponent,
        VorversicherungenWohngebaudeComponent,
        VorversicherungenUnfallComponent,
        ZahlweiseComponent,
        ModulesListComponent,
        TarifsCalculationComponent,
        ModuleItemComponent,
        AddressModalComponent,
        MitarbeiterModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        PipesModule,
        BrMaskerModule,
    ],
    exports: [
        HeaderComponent,
        LoginComponent,
        FooterComponent,
        HaftpflichtComponent,
        HausratComponent,
        WohngebaeudeComponent,
        TariflinieComponent,
        AddressFieldsComponent,
        DatePickerComponent,
        StandardButtonComponent,
        TarifTabsComponent,
        ClientFieldsComponent,
        SepaFieldsComponent,
        VorversicherungenHaftpflichtComponent,
        VorversicherungenHausratComponent,
        VorversicherungenWohngebaudeComponent,
        VorversicherungenUnfallComponent,
        ZahlweiseComponent,
        ModulesListComponent,
        TarifsCalculationComponent,
        ModuleItemComponent,
        AddressModalComponent,
        MitarbeiterModalComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }
