import { Component, EventEmitter, Output } from '@angular/core';
import { ContextService } from 'src/app/services/context.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-tariflinie',
  templateUrl: './tariflinie.component.html',
})
export class TariflinieComponent {

  @Output() recalculate = new EventEmitter<boolean>();
  constructor(public cs: ContextService, private notificationService: NotificationService) { }

  onTariflinieChanged() {
    this.notificationService.publishSomeData({
      recalculate: true,
      tariflinie: true,
      payment_frequency: false
  });
  }

}
