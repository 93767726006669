
import { Component, EventEmitter, Input } from '@angular/core';
import { HausratService } from 'src/app/services/calculators/hausrat.service';

import { ContextService } from 'src/app/services/context.service';
import { ValidationService } from 'src/app/services/validation.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-hausrat',
  templateUrl: './hausrat.component.html',
})
export class HausratComponent {

  qmInvalid: string;
  fahrraddiebstahlArr = [];
  calculationDone: boolean = false;

  constructor(public vs: ValidationService, public cs: ContextService, public notificationService: NotificationService, public calculator: HausratService) {
  }

  selectVariant(variant: string) {
    this.cs.process.products.hausrat.input.variant = variant;
    this.generateFahrraddiebstahlArr(variant);
  }

  generateFahrraddiebstahlArr(variant: string) {
    this.fahrraddiebstahlArr.length = 0;
    for (let i = variant === 'premium_plus' ? 2 : 1; i <= 10; i++) {
      this.fahrraddiebstahlArr.push(i * 1000);
    }
  }

  recalculate() {
    this.calculator.getCalculation();
    this.calculationDone = Number.isFinite(this.cs.process.products.hausrat.calculation.gesamtZahlGemZW);
  }

  validateQm() {
    this.qmInvalid = this.vs.validateQm(this.cs.process.products.hausrat.input.wohnflaeche);
  }

  ngOnInit() {
    this.generateFahrraddiebstahlArr(this.cs.process.products.tariflinie);
    this.notificationService.getObservable().subscribe((data) => {
      console.log('Data received', data);
      if (data.tariflinie)
        this.generateFahrraddiebstahlArr(this.cs.process.products.tariflinie);
      if (data.recalculate) {
        try {
          this.recalculate();
        } catch (err) {
          console.log("catched", err);
        }
      }
    });
  }


}
